<template>
    <SchoolAccessAbnormalSettingScrollWrapper></SchoolAccessAbnormalSettingScrollWrapper>
</template>
<script>
import SchoolAccessAbnormalSettingScrollWrapper from '@/components/scrollWrapper/SchoolAccessAbnormalSettingScrollWrapper.vue';
export default {
    name: "SchoolAccessAbnormalSetting",
    components: {
        SchoolAccessAbnormalSettingScrollWrapper,
    }
}
</script>